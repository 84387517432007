<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Batch Lessons">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th>Course</th>
                  <th>Video Type</th>
                  <th>Video ID</th>

                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(batch, index) in batcheLessons"
                  :key="index"
                  v-if="batcheLessons.length"
                >
                  <td class="align-middle text-center">
                    {{ 1 + index }}
                  </td>
                  <td class="align-middle">
                    <b>{{ batch.name }}</b>
                  </td>

                  <td>
                    <p class="mb-0">{{ batch.course.name }}</p>
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ batch.video_type == 1 ? "Youtube" : "Vimeo" }}
                    </p>
                  </td>
                  <td>
                    <p class="mb-1">{{ batch.video_id }}</p>
                  </td>

                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(batch.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </button>
                    <button
                      class="btn btn-danger btn-sm ml-1"
                      @click="Delete(batch.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Lesson'">
          <b-card-text>
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group label="Lesson Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="batch name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Enter Your Lesson Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group label="Video Type" label-for="video_type">
                      <validation-provider
                        #default="{ errors }"
                        name="Select Course"
                      >
                        <v-select
                          id="video_type"
                          v-model="form.video_type"
                          placeholder="Select Video Type"
                          :options="videoTypeOptions"
                          label="name"
                          :reduce="(videoTypeOptions) => videoTypeOptions.id"
                        >
                          <template #course="{ name }">
                            <span>{{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <b-form-group label="Video ID" label-for="video_id">
                      <validation-provider
                        #default="{ errors }"
                        name="video id"
                        rules="required"
                      >
                        <b-form-input
                          id="video_id"
                          v-model="form.video_id"
                          :state="errors.length > 0 ? false : null"
                          name="video_id"
                          placeholder="Enter Video ID"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <!-- submit buttons -->

                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      form: {
        id: "",
        name: "",
        batch_id: this.$route.params.id,
        video_type: "",
        video_id: "",
      },
      batcheLessons: [],
      videoTypeOptions: [
        {
          id: 1,
          name: "Vimeo",
        },
        {
          id: 2,
          name: "Youtube",
        },
      ],
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    async getResults() {
      const batches = await this.callApi(
        "get",
        `/app/batch/lesson?batch_id=` + this.form.batch_id
      );
      if (batches.status == 200) {
        this.batcheLessons = batches.data.batcheLessons;
      }
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/app/batch/lesson/" + id).then((res) => {
            this.getResults();
            this.Close();
            this.select.data = [];
            this.s(res.data.message);
          });
        }
      });
    },

    Close() {
      this.form.id = "";
      this.form.name = "";
      this.form.video_type = "";
      this.form.video_id = "";
    },

    async edit(id) {
      const batchLesson = await this.callApi("get", "/app/batch/lesson/" + id);
      if (batchLesson.status == 200) {
        this.form.id = batchLesson.data.lesson.id;
        this.form.name = batchLesson.data.lesson.name;
        this.form.course_id = batchLesson.data.lesson.course_id;
        this.form.video_type = batchLesson.data.lesson.video_type;
        this.form.video_id = batchLesson.data.lesson.video_id;
      }
    },

    update() {
      axios
        .put("/app/batch/lesson/" + this.form.id, this.form)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.course_id)
              this.e(e.response.data.errors.course_id[0]);
            if (e.response.data.errors.teacher_id)
              this.e(e.response.data.errors.teacher_id[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
          }
        });
    },

    add() {
      const fd = new FormData();
      fd.append("name", this.form.name);
      fd.append("course_id", this.form.course_id);
      fd.append("batch_id", this.form.batch_id);
      fd.append("video_type", this.form.video_type);
      fd.append("video_id", this.form.video_id);

      axios
        .post("/app/batch/lesson", fd)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.course_id)
              this.e(e.response.data.errors.course_id[0]);
            if (e.response.data.errors.teacher_id)
              this.e(e.response.data.errors.teacher_id[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          if (this.form.id) {
            this.update();
          } else {
            this.add();
          }
        }
      });
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "batch.lesson.access") {
          this.permission.access = true;
        }

        if (permission.slug == "batch.lesson.create") {
          this.permission.create = true;
        }

        if (permission.slug == "batch.lesson.update") {
          this.permission.edit = true;
        }

        if (permission.slug == "batch.lesson.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },

  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
};
</script>

<style></style>
